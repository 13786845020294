import { Injectable } from '@angular/core';
import { MatSnackBar } from "@angular/material";

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(private _snackBar: MatSnackBar) { }


  getCallStatuses() {
    return [
      {
        id: 1,
        text: 'Interested',
      },
      {
        id: 2,
        text: 'Not interested',
      },
      {
        id: 3,
        text: 'Call again',
      },
      {
        id: 4,
        text: 'Do not call',
      }
    ];
  }

  getGroupNumbers() {
    return [1, 2, 3, 4, 5, 6]
  }

  getStatusText(statusId) {
    let callStatus = this.getCallStatuses().find(c => c.id === statusId);
    if (callStatus) {
      return callStatus.text;
    } else {
      return '';
    }
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 8000,
      verticalPosition: 'top'
    });
  }
}
