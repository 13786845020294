import { Component, ChangeDetectionStrategy, ViewChild, TemplateRef, OnInit, } from '@angular/core';
import { startOfDay, isSameDay, isSameMonth } from 'date-fns';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CalendarDateFormatter, CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView } from 'angular-calendar';
import { ApiService } from '../services/api.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { CustomDateFormatter } from './date-formatter.provider';
import { Colors, StatusCall } from '../shared/constants';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css'],
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter,
    },
  ],
})

export class ReportsComponent implements OnInit {
  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;
  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  modalData: {
    action: string;
    event: CalendarEvent;
  }; 

  refresh: Subject<any> = new Subject();
  events: CalendarEvent[] = [];
  activeDayIsOpen: boolean = true;
  constructor(public router: Router, private datepipe: DatePipe, private modal: NgbModal, private api: ApiService) { }

  ngOnInit() {
    this.api.getReportsNew().subscribe(data => {
      data.forEach(d => {
        d.start = startOfDay(new Date(d.date))
        d.title = `${d.statusCall}: ${d.count}`
        d.color = StatusCall[d.statusCall].color       
      })      
      this.events = data;
      this.refresh.next();
    });   
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: any): void {
    const date = this.datepipe.transform(event.start, 'yyyy-MM-dd');    
    this.router.navigate(['/report-details'], { queryParams: { date: date, statusCall: event.statusCall } });
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  getTotalCalls(day: any) {   
    var total = 0
    day.events.forEach(v => {      
      total += v.count;
    });
    return total;
  }
}
