import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  form: any;
  returnUrl: string;

  constructor(private router: Router, public route: ActivatedRoute, public auth: AuthService, private fb: FormBuilder) {
    this.form = fb.group({
      userName: ['', Validators.required],
      password: ['', Validators.required]
    });

    this.returnUrl = this.route.snapshot.queryParamMap.get('returnUrl');
  }

  ngOnInit() {
  }

  login(formValue) {   
    this.auth.login(formValue, () => {
      if (!this.returnUrl) {
        this.router.navigate(['/']);
      } else {
        this.router.navigateByUrl(this.returnUrl);
      }
    });    
  }
}

