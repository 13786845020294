import { Component, OnInit } from '@angular/core';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'day-report',
  templateUrl: './day-report.component.html',
  styleUrls: ['./day-report.component.css']
})
export class DayReportComponent implements OnInit {

  currentDate = new Date();
  dayReport: any = {};
  constructor(private api: ApiService) {
    this.api.getReports().subscribe(data => {
      this.dayReport = data;
    });

  }

  ngOnInit() {
   
  }

}
