import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router'
import { ApiService } from '../services/api.service';
import { CommonService } from '../services/common.service';

@Component({
  selector: 'add-householder',
  templateUrl: './add-householder.component.html',
  styleUrls: ['./add-householder.component.css']
})
export class AddHouseholderComponent implements OnInit {
  houseHolder: HouseHolder = {
    Id: 0,
    Name: '',
    Gender: '',
    MobileNumber: '09',
    Carrier: '',
    Address: '',
    PublisherId: 0
  }
  constructor(public route: ActivatedRoute, public router: Router, public api: ApiService, private common: CommonService) {
  }
    
  ngOnInit() {

  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  checkNetworkCarrier(event: Event) {
    if ((event.target as HTMLInputElement).value.length < 5) {
      this.houseHolder.Carrier = '';
      return;
    }
    this.houseHolder.MobileNumber = (event.target as HTMLInputElement).value;
    let network = PREFIX_NETWORK.filter(n => { return n.Prefix == this.houseHolder.MobileNumber.substring(0, 5); });
    if (network.length) {
      this.houseHolder.Carrier = network.length ? network[0].Network : '';
    } else {
      network = PREFIX_NETWORK.filter(n => { return n.Prefix == this.houseHolder.MobileNumber.substring(0, 4); });
      this.houseHolder.Carrier = network.length ? network[0].Network : '';
    }
  }

  saveHouseholder(navigation) {
    if (this.houseHolder.MobileNumber.length === 11) {
      this.houseHolder.Id = navigation ? 1 : 0;
      this.api.postHouseholder(this.houseHolder, (result) => {
        if (result) {
          if (navigation) {
            this.router.navigate(['householder/' + result.id])
            this.common.openSnackBar("A householder was saved and it was assigned to you. Thank you!", "OK")
          } else {
            this.houseHolder.Name = '';
            this.houseHolder.Gender = '';
            this.houseHolder.MobileNumber = '09';
            this.houseHolder.Carrier = '';
            this.houseHolder.Address= '';
            this.common.openSnackBar("A householder was saved! Thank you and please add again.", "OK")
          }
        }
      }, (err) => {
           this.common.openSnackBar("Duplicate record. Not saved. " + err.error, "OK");
          console.log(err)
      });
    }
  }
}

export interface HouseHolder {
  Id?: number;
  Name: string;
  Gender: string;
  MobileNumber: string;
  Carrier: string;
  Address: string;
  PublisherId?: number;
}

export interface Carrier {
  Prefix: string;
  Network: string;
}
const PREFIX_NETWORK: Carrier[] = [
  {
    "Prefix": "09636",
    "Network": "TNT"
  },
  {
    "Prefix": "0973",
    "Network": "Globe"
  },
  {
    "Prefix": "0817",
    "Network": "Globe"
  },
  {
    "Prefix": "0905",
    "Network": "TM"
  },
  {
    "Prefix": "0906",
    "Network": "TM"
  },
  {
    "Prefix": "0907",
    "Network": "TNT"
  },
  {
    "Prefix": "0908",
    "Network": "Smart"
  },
  {
    "Prefix": "0909",
    "Network": "TNT"
  },
  {
    "Prefix": "0910",
    "Network": "TNT"
  },
  {
    "Prefix": "0912",
    "Network": "TNT"
  },
  {
    "Prefix": "0915",
    "Network": "Globe"
  },
  {
    "Prefix": "0916",
    "Network": "Globe"
  },
  {
    "Prefix": "0917",
    "Network": "Globe"
  },
  {
    "Prefix": "0918",
    "Network": "Smart"
  },
  {
    "Prefix": "0919",
    "Network": "Smart"
  },
  {
    "Prefix": "0920",
    "Network": "Smart"
  },
  {
    "Prefix": "0921",
    "Network": "Smart"
  },
  {
    "Prefix": "0922",
    "Network": "Sun"
  },
  {
    "Prefix": "0923",
    "Network": "Sun"
  },
  {
    "Prefix": "0924",
    "Network": "Sun"
  },
  {
    "Prefix": "0925",
    "Network": "Sun"
  },
  {
    "Prefix": "0926",
    "Network": "TM"
  },
  {
    "Prefix": "0927",
    "Network": "TM"
  },
  {
    "Prefix": "0928",
    "Network": "Smart"
  },
  {
    "Prefix": "0929",
    "Network": "Smart"
  },
  {
    "Prefix": "0930",
    "Network": "TNT"
  },
  {
    "Prefix": "0931",
    "Network": "Sun"
  },
  {
    "Prefix": "0932",
    "Network": "Sun"
  },
  {
    "Prefix": "0933",
    "Network": "Sun"
  },
  {
    "Prefix": "0934",
    "Network": "Sun"
  },
  {
    "Prefix": "0935",
    "Network": "Globe"
  },
  {
    "Prefix": "0936",
    "Network": "Globe"
  },
  {
    "Prefix": "0937",
    "Network": "Globe"
  },
  {
    "Prefix": "0938",
    "Network": "TNT"
  },
  {
    "Prefix": "0939",
    "Network": "Smart"
  },
  {
    "Prefix": "0940",
    "Network": "Sun"
  },
  {
    "Prefix": "0941",
    "Network": "Sun"
  },
  {
    "Prefix": "0942",
    "Network": "Sun"
  },
  {
    "Prefix": "0943",
    "Network": "Sun"
  },
  {
    "Prefix": "0945",
    "Network": "Globe"
  },
  {
    "Prefix": "0946",
    "Network": "TNT"
  },
  {
    "Prefix": "0947",
    "Network": "Smart"
  },
  {
    "Prefix": "0948",
    "Network": "TNT"
  },
  {
    "Prefix": "0949",
    "Network": "Smart"
  },
  {
    "Prefix": "0950",
    "Network": "TNT"
  },
  {
    "Prefix": "0951",
    "Network": "Smart"
  },
  {
    "Prefix": "0953",
    "Network": "Globe"
  },
  {
    "Prefix": "0954",
    "Network": "Globe"
  },
  {
    "Prefix": "0955",
    "Network": "Globe"
  },
  {
    "Prefix": "0956",
    "Network": "Globe"
  },
  {
    "Prefix": "0961",
    "Network": "Smart"
  },
  {
    "Prefix": "0965",
    "Network": "Globe"
  },
  {
    "Prefix": "0966",
    "Network": "Globe"
  },
  {
    "Prefix": "0967",
    "Network": "Globe"
  },
  {
    "Prefix": "0973",
    "Network": "Sun"
  },
  {
    "Prefix": "0974",
    "Network": "Sun"
  },
  {
    "Prefix": "0975",
    "Network": "Globe"
  },
  {
    "Prefix": "0977",
    "Network": "Globe"
  },
  {
    "Prefix": "0978",
    "Network": "Globe"
  },
  {
    "Prefix": "0979",
    "Network": "Globe"
  },
  {
    "Prefix": "0995",
    "Network": "Globe"
  },
  {
    "Prefix": "0996",
    "Network": "Globe"
  },
  {
    "Prefix": "0997",
    "Network": "Globe"
  },
  {
    "Prefix": "0998",
    "Network": "Smart"
  },
  {
    "Prefix": "0999",
    "Network": "Smart"
  },
  {
    "Prefix": "09173",
    "Network": "Globe"
  },
  {
    "Prefix": "09175",
    "Network": "Globe"
  },
  {
    "Prefix": "09176",
    "Network": "Globe"
  },
  {
    "Prefix": "09178",
    "Network": "Globe"
  },
  {
    "Prefix": "09253",
    "Network": "Globe"
  },
  {
    "Prefix": "09255",
    "Network": "Globe"
  },
  {
    "Prefix": "09256",
    "Network": "Globe"
  },
  {
    "Prefix": "09257",
    "Network": "Globe"
  },
  {
    "Prefix": "09258",
    "Network": "Globe"
  },
  {
    "Prefix": "0963",
    "Network": "Globe"
  },
  {
    "Prefix": "0964",
    "Network": "Globe"
  }
];
