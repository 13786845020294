import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material'
import { MatTableModule } from '@angular/material/table';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { MatChipsModule } from '@angular/material/chips';
import { MatBadgeModule } from '@angular/material/badge';
import { MatDatepickerModule } from '@angular/material/datepicker';

import { MatRadioModule } from '@angular/material/radio';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatPaginatorModule } from '@angular/material';
import { MatSortModule } from '@angular/material';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';

import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { CounterComponent } from './counter/counter.component';
import { FetchDataComponent } from './fetch-data/fetch-data.component';
import { PublishersComponent } from './publishers/publishers.component';

import { ApiService } from './services/api.service';
import { AuthService } from './services/auth.service'
import { AuthGuardService } from './services/auth-guard.service';
import { CommonService } from './services/common.service';
import { AuthInterceptorService } from './services/auth-interceptor.service';
import { PublisherComponent } from './publishers/publisher.component';
import { LoginComponent } from './account/login.component';
import { HouseholdersComponent } from './householders/householders.component';
import { HouseholderComponent } from './householders/householder.component';
import { CallStatusComponent } from './householders/call-status.component';
import { AddHouseholderComponent } from './householders/add-householder.component';
import { MatNativeDateModule } from '@angular/material/core';
import { ResetPasswordComponent } from './account/reset-password.component';
import { DayReportComponent } from './reports/day-report.component';
import { ReportsComponent } from './reports/reports.component';
import { ReportDetailsComponent } from './reports/report-details.component';
import { AddCallHistoryComponent } from './householders/add-callhistory.component';
import { ArchivedComponent } from './householders/archived.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    CounterComponent,
    FetchDataComponent,
    PublishersComponent,
    PublisherComponent,
    LoginComponent,
    HouseholdersComponent,
    HouseholderComponent,
    CallStatusComponent,
    ResetPasswordComponent,
    DayReportComponent,
    ReportsComponent,
    ReportDetailsComponent,
    AddHouseholderComponent,
    AddCallHistoryComponent,
    ArchivedComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    MatButtonModule,
    MatSnackBarModule,
    MatTableModule,
    MatButtonModule,
    MatCardModule,
    MatTabsModule,
    MatChipsModule,
    MatFormFieldModule,
    MatIconModule,
    MatBadgeModule,
    MatDatepickerModule,
    MatInputModule,
    BrowserAnimationsModule,
    MatSelectModule,
    MatRadioModule,
    MatNativeDateModule,
    MatDividerModule,
    ReactiveFormsModule,
    MatExpansionModule,
    MatSnackBarModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatSortModule,
    MatDialogModule,
    RouterModule.forRoot([
      { path: '', component: HomeComponent, pathMatch: 'full' },
      { path: 'counter', component: CounterComponent },
      { path: 'fetch-data', component: FetchDataComponent },
      {
        path: 'archived',
        component: ArchivedComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: 'reports',
        component: ReportsComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: 'report-details',
        component: ReportDetailsComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: 'householders',
        component: HouseholdersComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: 'householder/:id',
        component: HouseholderComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'add-householder',
        component: AddHouseholderComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'publishers',
        component: PublishersComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: 'publisher/:id',
        component: PublisherComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'reset-password/:id',
        component: ResetPasswordComponent,
        canActivate: [AuthGuardService]
      },
      { path: 'login', component: LoginComponent },
      { path: 'login/:url', component: LoginComponent },
    ]),
    BrowserAnimationsModule,
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory })
  ],
  providers: [DatePipe, ApiService, AuthService, AuthGuardService, AuthInterceptorService, CommonService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    }],
  bootstrap: [AppComponent],
  entryComponents: [AddCallHistoryComponent]
})
export class AppModule { }
