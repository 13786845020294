export interface Publishers {
  id: number;
  name: string;
  groupNumber: number;
}

export interface StatusDetail {
  id: number;
  details: string;
  householderId: number;
  statusCall: string;
  statusDate: Date;
}

export class AppUserObject {
  userName: string = "";
  bearerToken: string = "";
  isAuthenticated: boolean = false;
  isAdmin: boolean = false;
  canAddPublisher: boolean = false; //UNUSED
  canAddHouseholder: boolean = false; // UNUSED
}
