export const Colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
  green: {
    primary: '#00ff00',
    secondary: '#00ff00',
  },
};

export const StatusCall: IStatusCall = {
  'Interested': {
    color: Colors.green,
    apiCode: 'in'
  },
  'Not interested': {
    color: Colors.yellow,
    apiCode: 'ni'
  },
  'Call again': {
    color: Colors.blue,
    apiCode: 'ca'
  },
  'Do not call': {
    color: Colors.red,
    apiCode: 'dc'
  },
}

interface IStatusCall {
  [key: string]: any;
} 
