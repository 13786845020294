import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ApiService } from '../services/api.service';
import { ActivatedRoute, Router } from '@angular/router'
import { CommonService } from '../services/common.service';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-publisher',
  templateUrl: './publisher.component.html',
  styleUrls: ['./publisher.component.css']
})
export class PublisherComponent implements OnInit {

  form: any;
  publisherId: number;
  publisher: any = {};
  groupNumbers = [1, 2, 3, 4, 5, 6];

  constructor(private route: ActivatedRoute, private router: Router, private fb: FormBuilder, private api: ApiService, private common: CommonService, public auth: AuthService) {
    this.form = fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });

  }

  ngOnInit() {
    this.publisherId = +this.route.snapshot.paramMap.get('id');
    if (this.publisherId > 0) {
      this.api.getPublisher(this.publisherId).subscribe(data => {
        this.publisher = data;
      });
    }    
  }

  //Administrator: admin/admin@Fort
  savePublisher() {
    if (this.publisherId > 0) {
      this.api.putPublisher(this.publisher, () => this.common.openSnackBar("A publisher was saved!", "Ok"));      
    } else {
      this.api.postPublisher(this.publisher, () => this.router.navigate(['publishers']));      
    }
  }
}
