import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from '../services/common.service';

@Component({
  selector: 'call-status',
  templateUrl: './call-status.component.html',
  styleUrls: ['./call-status.component.css']
})
export class CallStatusComponent implements OnInit {

  @Input() householder: any = {};


  @Input() callStatuses: any = [];

  constructor(public common: CommonService) { }

  //ERR if missing in ng build prod
  panelOpenState;
  panelOpenState2;
  panelOpenState3;
  panelOpenState4;
  panelOpenState5;
  ngOnInit() {
  }



}
