import { Inject, Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import { Router } from '@angular/router';
import { AppUserObject } from '../models/models';
import { CommonService } from './common.service';

@Injectable()
export class AuthService {
  appUser: AppUserObject = new AppUserObject();

  credentials: any;
  errorMessage: any;

  constructor(private http: HttpClient, private router: Router, @Inject('BASE_URL') private baseUrl: string, private common: CommonService) { }

  login(credentials, successAction) {
    this.http.post<any>(`${this.baseUrl}api/account/login`, credentials).subscribe(res => {
      if (res.bearerToken) {
        this.authenticate(res);
        successAction();
      } else {
        this.errorMessage = res.error;
      }
    });
  }

  authenticate(res) {
    localStorage.setItem('client_user', JSON.stringify(res))
  }

  logout() {
    localStorage.removeItem('client_user');
    this.appUser = new AppUserObject();
    this.router.navigate(['/login']);
  }

  resetPassword(credentials) {    
    this.http.post<any>(`${this.baseUrl}api/account/resetpassword`, credentials).subscribe(res => {
      if (res) {
        this.common.openSnackBar("Password has been changed!", "Ok")
      } else {
        this.common.openSnackBar("ERROR! Password NOT changed.", "Ok")
      }
    });
  }

  deleteUser(userName) {

    if (confirm("Are you sure you want to delete this publisher?")) {
      let httpParams = new HttpParams().set('userName', userName);
      let options = { params: httpParams };

      this.http.delete<any>(`${this.baseUrl}api/account`, options).subscribe(res => {
        debugger;
        if (res.succeeded) {
          this.router.navigate(['publishers']);
        } else {
          this.common.openSnackBar("ERROR! Unassign householders first.", "Ok")
        }

      });
    }

  }

  get user() {
    //TODO: Check implementation
    let clientUser = localStorage.getItem('client_user');
    if (clientUser) {
      this.appUser = JSON.parse(clientUser);
      this.appUser.isAuthenticated = !!this.appUser.bearerToken;
    }
    return this.appUser;
  }

}
