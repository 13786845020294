import { Component, OnInit, Inject, AfterViewInit, ViewChildren, QueryList } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { AuthService } from '../services/auth.service';
import { ApiService } from '../services/api.service';
import { CommonService } from '../services/common.service';
import { ActivatedRoute, Router } from '@angular/router'
import { Householder } from '../householders/householders.component';
import { StatusCall } from '../shared/constants';

export interface User {
  userName: string;
}

@Component({
  selector: 'report-details',
  templateUrl: './report-details.component.html',
  styleUrls: ['./report-details.component.css']
})
export class ReportDetailsComponent implements OnInit {
  displayedColumns: string[] = ['id', 'name', 'gender', 'mobileNumber', 'carrier', 'address', 'action'];
  length: number;
  dataSource = new MatTableDataSource<Householder>();
  public userObject: any;

  callDate: string = '';
  statusCall: string = '';

  constructor(public api: ApiService, auth: AuthService, private common: CommonService, public route: ActivatedRoute, public router: Router,) {
    this.userObject = auth.user;
    this.route.queryParams.subscribe(params => {
      this.callDate = params['date'];
      this.statusCall = params['statusCall'];
    });
  }

  @ViewChildren(MatPaginator) paginators: QueryList<MatPaginator>;
  @ViewChildren(MatSort) sorts: QueryList<MatSort>;

  ngOnInit() {
    const callCode = StatusCall[this.statusCall].apiCode;
    this.api.getByStatusDate(callCode, this.callDate).subscribe(results => {
      this.dataSource = new MatTableDataSource<Householder>(results);
      this.dataSource.paginator = this.paginators.toArray()[0];
      this.dataSource.sort = this.sorts.toArray()[0];
      this.length = results.length;
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
