import { Component, OnInit, Inject, AfterViewInit, ViewChildren, ViewChild, QueryList } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { AuthService } from '../services/auth.service';
import { ApiService } from '../services/api.service';
import { CommonService } from '../services/common.service';
import { ActivatedRoute, Router } from '@angular/router'

export interface Householder {
  id: number;
  name: string;
  gender: string;  
  mobileNumber: string;
  carrier: string;
  address: string;
  publisher: any;
  statusDetails: any;
  archived: boolean;
  action: string;
}

export interface User {
  userName: string;
}

@Component({
  selector: 'app-householders',
  templateUrl: './householders.component.html',
  styleUrls: ['./householders.component.css']
})
export class HouseholdersComponent implements AfterViewInit {
  displayedColumns: string[] = ['id', 'name', 'gender', 'mobileNumber', 'carrier', 'address', 'publisher', 'action'];
  displayUnAssignedColumns: string[] = ['id', 'name', 'gender', 'mobileNumber', 'carrier', 'address', 'action'];
  householders: Householder[];
  length: number;
  activeTab;
  tabs = [
    { name: 'MyContacts', dataSource: new MatTableDataSource<Householder>([]) },
    { name: 'AvailableContacts', dataSource: new MatTableDataSource<Householder>([]) }
  ]; 
  public userObject: any;

  constructor(public api: ApiService, auth: AuthService, private common: CommonService, public route: ActivatedRoute, public router: Router,) {
    this.userObject = auth.user;
  }

  @ViewChildren(MatPaginator) paginators: QueryList<MatPaginator>;
  @ViewChildren(MatSort) sorts: QueryList<MatSort>;

  ngAfterViewInit() {
  }

  ngOnInit() {
    this.activeTab = this.tabs[0];
    this.api.getHouseholders().subscribe(results => {
      this.householders = results;
      this.activeTab.dataSource = new MatTableDataSource<Householder>(this.filterMyContacts(results));
      this.activeTab.dataSource.paginator = this.paginators.toArray()[0];
      this.activeTab.dataSource.sort = this.sorts.toArray()[0];
      this.length = this.filterMyContacts(results).length;
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.tabs[0].dataSource.filter = filterValue.trim().toLowerCase();
    this.tabs[1].dataSource.filter = filterValue.trim().toLowerCase();
  }

  onTabChange(event) {
    this.activeTab = this.tabs[event.index];
    let source = event.index === 0 ? this.filterMyContacts(this.householders) : this.filterUnAssigned(this.householders);
    this.activeTab.dataSource = new MatTableDataSource<Householder>(source);
    this.activeTab.dataSource.paginator = this.paginators.toArray()[event.index];
    this.activeTab.dataSource.sort = this.sorts.toArray()[event.index];
    this.length = source.length;
  }

  filterUnAssigned(householders: any[]) {
    return householders.filter(h => h.publisher === null);
  }
  filterMyContacts(householders: any[]) {
    return householders.filter(h => h.publisher !== null && h.publisher.userName.toLowerCase() == this.userObject.userName.toLowerCase())
  }

  getNumber(householder) {
    this.api.getHouseholder(householder.id).subscribe(data => {
      let hh:any = data;
      if (!hh.publisher) {
        this.api.putHouseholder(householder, () => {
          this.common.openSnackBar("A householder has been assigned to you. Thank you!", "Ok")
          this.router.navigate(['householder/' + householder.id])
        });
      } else {
        this.common.openSnackBar("A householder has been assigned to other publisher. Please select other contacts.", "Ok")
        this.activeTab = this.tabs[1];
        this.api.getHouseholders().subscribe(results => {
          this.householders = results;
          this.activeTab.dataSource = new MatTableDataSource<Householder>(this.filterUnAssigned(results));
          this.activeTab.dataSource.paginator = this.paginators.toArray()[1];
          this.activeTab.dataSource.sort = this.sorts.toArray()[1];
          this.length = this.filterUnAssigned(results).length;
        });
      }
    });
    
  }
}
