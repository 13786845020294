import { Component, OnInit, Inject} from '@angular/core';
import { CommonService } from '../services/common.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
@Component({
  selector: 'add-callhistory',
  templateUrl: './add-callhistory.component.html',
  styleUrls: ['./add-callhistory.component.css']
})
export class AddCallHistoryComponent implements OnInit {
  form: FormGroup;
  details: string;
  callStatuses: any = [];
  tomorrow = new Date();
  dateStatus = new FormControl(new Date(), [Validators.required]);
  currentStatus: string;

  constructor(private common: CommonService, private fb: FormBuilder,
    private dialogRef: MatDialogRef<AddCallHistoryComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
    this.callStatuses = common.getCallStatuses();
  }

  ngOnInit() {
    this.form = this.fb.group({
      details: this.details,
      dateStatus: this.dateStatus,
      currentStatus: this.currentStatus
    });
  }
    
  save() {
    this.dialogRef.close(this.form.value);
  }

  close() {
    this.dialogRef.close();
  }

  getErrorMessage() {
    if (this.dateStatus.hasError('required')) {
      return 'You must select a date';
    }
    return this.dateStatus.hasError('dateStatus') ? 'Not a valid date. Please select a valid date.' : '';
  }
}
