import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router'
import { ApiService } from '../services/api.service';
import { CommonService } from '../services/common.service';
import { Publishers, StatusDetail } from '../models/models';
import { FormBuilder } from '@angular/forms';
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { error } from 'protractor';
import { MatDialog, MatDialogConfig } from "@angular/material";
import { AddCallHistoryComponent } from './add-callhistory.component';

@Component({
  selector: 'householder',
  templateUrl: './householder.component.html',
  styleUrls: ['./householder.component.css']
})
export class HouseholderComponent implements OnInit {

  householderId: number;
  householder: any = {
    publisher: {} //TODO: undefined error for null in model
  }
  publishers: Publishers[];
  statusDetails = new MatTableDataSource<StatusDetail>();

  constructor(public route: ActivatedRoute, public router: Router, public api: ApiService, private common: CommonService, fb: FormBuilder, private dialog: MatDialog) {
    this.api.getPublishers().subscribe(data => {
      this.publishers = data
    });
    this.householderId = +this.route.snapshot.paramMap.get('id');

  }
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  ngOnInit() {
    this.getCallHistory();
  }

  addCallHistory() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '600px';
    dialogConfig.height = '350px';
    const dialogRef = this.dialog.open(AddCallHistoryComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      (data) => {
        if (data) {
          this.saveCallHistory(data);
        }
      }
    );    
  }

  saveHouseholder(navigate) {    
    if (this.householderId > 0) {
      this.api.putHouseholder(this.householder, () => {
        this.common.openSnackBar("A householder was saved!", "Ok")
        if (navigate) {
          this.router.navigate(['householders'])
        } else {
          this.router.navigate(['archived'])
        }
      });      
    } else {
      this.api.postHouseholder(this.householder, () => {
        if (navigate) { this.router.navigate(['householders']) }
      }, (error) => console.log(error));      
    }
  }

  saveCallHistory(data) {
    let statusDetail = {
      statusCall: data.currentStatus,
      details: data.details,
      householderId: this.householderId,
      date: data.dateStatus.toISOString()
    }
    this.api.postHouseholderStatus(statusDetail, () => {
      this.common.openSnackBar("A householder status was updated!", "Ok")
      this.getCallHistory();
      this.api.putHouseholder(this.householder, () => {
        console.log("Householder was save!");
      });
    });
 }

  getCallHistory() {
    if (this.householderId > 0) {
      this.api.getHouseholder(this.householderId).subscribe(data => {
        this.householder = data;
        this.statusDetails = new MatTableDataSource<StatusDetail>(this.householder.statusDetails);
        this.statusDetails.sort = this.sort;
      });
    }
  }

  deleteCallHistory(event) {
    this.api.deleteHouseholderHistory(event.id, () => {
      const index = this.statusDetails.data.indexOf(event, 0);
      if (index > -1) {
        this.statusDetails.data.splice(index, 1);
      }
      this.getCallHistory();
      this.common.openSnackBar("A householder call history was deleted! Dated: " + new Date(event.statusDate).toDateString(), "Ok")
    });
  }

  archivedHouseholder(archive) {
    this.householder.archived = archive;
    this.saveHouseholder(!archive);
  }
}
