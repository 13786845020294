import { ApiService } from '../services/api.service';

import { OnInit, AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-publishers',
  templateUrl: './publishers.component.html',
  styleUrls: ['./publishers.component.css']
})
export class PublishersComponent implements OnInit  {
  publishers;
  displayedColumns: string[] = ['id', 'name', 'userName', 'group', 'email', 'action', 'action2'];

  //dataSource = new MatTableDataSource<any>();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(private api: ApiService) {
    
  }

  ngOnInit() {
    this.api.getPublishers().subscribe(data => {
      this.publishers = new MatTableDataSource<any>(data);
      this.publishers.paginator = this.paginator;
      this.publishers.sort = this.sort;
    });
    
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.publishers.filter = filterValue.trim().toLowerCase();
  }  

}
