import { Injectable, Inject } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Subject } from 'rxjs';
import { Publishers } from '../models/models'
import { Householder } from '../householders/householders.component';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) { }

  getPublishers() {
    return this.http.get<Publishers[]>(this.baseUrl + 'api/publishers');
  }

  getPublisher(id: number) {
    return this.http.get(`${this.baseUrl}api/publishers/${id}`);
  }

  postPublisher(publisher, callback) {
    this.http.post(this.baseUrl + 'api/publishers', publisher).subscribe(res => {
      callback();
    })
  }

  putPublisher(publisher, callback) {
    this.http.put(`${this.baseUrl}api/publishers/${publisher.id}`, publisher).subscribe(res => {
      callback();
    })
  }

  getHouseholders() {
    return this.http.get<Householder[]>(this.baseUrl + 'api/householders');
  }

  getArchived() {
    return this.http.get<Householder[]>(this.baseUrl + 'api/householders/archived');
  }

  getByStatusDate(callCode: string, callDate: string) {
    return this.http.get<Householder[]>(`${this.baseUrl}api/householders/status/${callCode}?callDate=${callDate}`);
  }

  restoreHouseholder(id: number, callback) {
    return this.http.get(`${this.baseUrl}api/householders/restored/${id}`).subscribe(res => {
      callback();
    });
  }

  getHouseholder(id: number) {
    return this.http.get(`${this.baseUrl}api/householders/${id}`);
  }

  deleteHouseholderHistory(id: number, callback) {
    return this.http.delete(`${this.baseUrl}api/householders/${id}`).subscribe(data => {
      if (data) console.log('Deleted call history Id: ' + id);
      callback();
    });
  }

  postHouseholder(householder, callback, error) {    
    this.http.post(this.baseUrl + 'api/householders', householder).subscribe(res => {
      callback(res);
    }, err => { error(err); })
  }

  postHouseholderStatus(statusDetail, callback) {
    this.http.post(this.baseUrl + 'api/householders/status/' + statusDetail.date, statusDetail).subscribe(res => {
      callback();
    })
  }

  putHouseholder(householder, callback) {
    this.http.put(`${this.baseUrl}api/householders/${householder.id}`, householder).subscribe(res => {
      callback();
    })
  }

  getReports() {
    return this.http.get<any>(this.baseUrl + 'api/reports');
  }

  getReportsNew() {
    return this.http.get<any>(this.baseUrl + 'api/reports/new');
  }
}
