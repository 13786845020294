import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  form: any;
  userName: any;
  constructor(public auth: AuthService, private route: ActivatedRoute, private fb: FormBuilder) {
    this.form = fb.group({
      password: ['', [Validators.required, Validators.minLength(4)]],
      confirmPassword: ['']
    }, {validator: this.comparePasswords}
    );
  }

  ngOnInit() {
    this.userName = this.route.snapshot.paramMap.get('id');
  }

  resetPassword(formValues) {    
    
    if (this.userName) {
      var credentials = {
        userName: this.userName,
        password: formValues.password
      }      
      this.auth.resetPassword(credentials);
    }
  }

  comparePasswords(group: FormGroup) { // here we have the 'passwords' group
    let pass = group.get('password').value;
    let confirmPass = group.get('confirmPassword').value;

    return pass === confirmPass ? null : { notSame: true }
  }

}
