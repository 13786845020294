import { Component, OnInit, Inject, AfterViewInit, ViewChildren, ViewChild, QueryList } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { AuthService } from '../services/auth.service';
import { ApiService } from '../services/api.service';
import { CommonService } from '../services/common.service';
import { ActivatedRoute, Router } from '@angular/router'
import { Householder } from './householders.component';

export interface User {
  userName: string;
}

@Component({
  selector: 'archived',
  templateUrl: './archived.component.html',
  styleUrls: ['./archived.component.css']
})
export class ArchivedComponent implements OnInit {
  displayedColumns: string[] = ['id', 'name', 'gender', 'mobileNumber', 'carrier', 'address', 'action'];
  length: number;
  dataSource = new MatTableDataSource<Householder>();
  public userObject: any;

  constructor(public api: ApiService, auth: AuthService, private common: CommonService, public route: ActivatedRoute, public router: Router,) {
    this.userObject = auth.user;
  }

  @ViewChildren(MatPaginator) paginators: QueryList<MatPaginator>;
  @ViewChildren(MatSort) sorts: QueryList<MatSort>;

  ngAfterViewInit() {
    this.api.getArchived().subscribe(results => {
      this.dataSource = new MatTableDataSource<Householder>(results);
      this.dataSource.paginator = this.paginators.toArray()[0];
      this.dataSource.sort = this.sorts.toArray()[0];
      this.length = results.length;
    });
  }

  ngOnInit() {
    this.api.getArchived().subscribe(results => {
      this.dataSource = new MatTableDataSource<Householder>(results);
      this.dataSource.paginator = this.paginators.toArray()[0];
      this.dataSource.sort = this.sorts.toArray()[0];
      this.length = results.length;
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  restore(event) {
    if (event) {
      this.api.restoreHouseholder(event.id, () => {
        this.router.navigate(['householder/' + event.id])
      });
    }

  }
}
